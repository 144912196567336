import React, { useEffect } from 'react';
import WebViewer from '@pdftron/webviewer';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';


const BD_FILE_URL = 'https://api.quickbase.com/v1/files/brbgxb4nr/'
const BG_HEADERS = {
    'QB-Realm-Hostname': 'bgprod.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b47mvm_m6md_0_fg7ngqmnrdtxdfsnivhb2xg6h9',
    'Content-Type': 'application/json'
}

// notification
function notification(message) {
    NotificationManager.info(message);
}

let fileType = ""


async function pushAnnotations(rid, data) {
    const headers = BG_HEADERS
    const body = {
        "to": "brbgxb4nr",
        "data": [
            {
                "3": { "value": rid },
                "34": { "value": data },
            }
        ],
        "fieldsToReturn": [
            34
        ]
    }

    fetch('https://api.quickbase.com/v1/records',
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then(res => {
            if (res.ok) {
                return res.json().then(res => console.log(res));
            }
            return res.json().then(resBody => Promise.reject({ status: res.status, ...resBody }));
        })
        .catch(err => console.log(err))
}

async function pullAnnotations(rid) {
    const headers = BG_HEADERS
    const body = {
        "from": "brbgxb4nr",
        "select": [
            3,
            34,
        ],
        "where": `{3.EX.${rid}}`
    }

    const response = await fetch('https://api.quickbase.com/v1/records/query',
        {
            method: 'post',
            headers: headers,
            body: JSON.stringify(body)
        })
    const res = response.json()
    return res
}


async function getPDF(url, rid) {
    const response = await fetch(url + rid + '/9/0', {
        method: 'GET',
        headers: BG_HEADERS
    })

    if (response.ok) {

        const reader = response.body.getReader();
        const decoder = new TextDecoder()

        let base64String = ''
        while (true) {
            const { done, value } = await reader.read()
            if (done) {
                break
            }
            base64String += decoder.decode(value)
        }


        // decode base64 string and determine file type
        const decoded = atob(base64String);
        const slice = decoded.slice(0, 4);
        let blobType;
        switch (slice) {
            case '\x89PNG':
                fileType = 'png';
                blobType = "image/png"
                break;
            case 'GIF8':
                fileType = 'gif';
                break;
            case '\xFF\xD8\xFF\xE0':
            case '\xFF\xD8\xFF\xE1':
            case '\xFF\xD8\xFF\xE2':
                fileType = 'jpg';
                blobType = "image/jpeg"
                break;
            case '%PDF':
                fileType = 'pdf';
                blobType = "application/pdf"
                break;
            default:
                fileType = 'unknown';
                break;
        }

        console.log(fileType)
        // decode base64 string, remove space for IE compatibility
        let binary = atob(base64String);
        let len = binary.length;
        let buffer = new ArrayBuffer(len);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        console.log("We are making a blob")
        // create the blob object with content-type "application/pdf"
        let blob = new Blob([view], { type: blobType });
        return blob.arrayBuffer()
    }
}

// Add arrayBuffer if necessary i.e. Safari
(function () {
    if (Blob.arrayBuffer !== "function") {
        Blob.prototype.arrayBuffer = myArrayBuffer;
    }

    function myArrayBuffer() {
        return new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.readAsArrayBuffer(this);
        });
    }
})();


export default class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            Viewer: {},
            AnnotationManager: {},
            Test: '100',
            SaveChangesNotification: 0,
            incompleteAnnotations: false,
            auth: false,
            viewerLoaded: false
        }
        this.urlArgs = this.props.match.params.id.split("-")
    }

    checkAuth() {
        const userName = this.urlArgs[0]
        const body = {
            "from": "brw5uwgh3",
            "select": [
                7
            ],
            "where": `{7.EX.${userName}}`,
        }

        fetch("https://api.quickbase.com/v1/records/query", {
            method: "POST",
            headers: BG_HEADERS,
            body: JSON.stringify(body)
        })
            .then(res => res.json())
            .then(
                (result) => {


                    if (result.metadata.totalRecords === 1) {
                        this.setState({
                            isLoaded: true,
                            auth: true,
                        });
                    } else {
                        this.setState({
                            isLoaded: true,
                            auth: false,
                        });
                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true
                    });
                }
            )
    }


    // set up view on load
    componentDidMount() {
        window.addEventListener('beforeunload', this.beforeunload.bind(this));
        this.checkAuth()
    }

    componentDidUpdate() {
        if (this.state.auth && this.state.viewerLoaded === false) {
            this.createView()
            this.setState({ viewerLoaded: true })
        }
    }

    beforeunload(e) {

        if (this.urlArgs[2] === "1") {
            if (this.state.incompleteAnnotations) {
                notification('There are unresolved annotations.')
                e.preventDefault();
                e.returnValue = true;
            }
        }

        if (this.state.SaveChangesNotification > 1) {
            e.preventDefault();
            e.returnValue = true;
        }
    }


    getCurrentUser() {
        return this.urlArgs[0][0].toUpperCase() + this.urlArgs[0].slice(1).toLowerCase();
    }

    getCurrentId() {
        return this.urlArgs[1];
    }

    async createView() {
        if (true) {
            let xfdfString = ''
            const annotations = await pullAnnotations(this.urlArgs[1]).then(res => xfdfString = res.data[0][34].value || "")
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(annotations, "text/xml");
            const annots = xmlDoc.getElementsByTagName("annots")[0];
            let myBlob = await getPDF(BD_FILE_URL, this.urlArgs[1]);
            console.log("Blob Type: " + myBlob)
            let viewer = WebViewer(
                {
                    path: '/webviewer/lib',
                    licenseKey: 'BG Products Inc :ENTERP:See Agreement::B+:AMS(20271027):E2C5B9F26A364D0B6ABBC8B253084F01004F2D56AD1399A505130A5250E2E331B6F5C7',
                    initialDoc: '',
                    extension: ['pdf', 'png', 'jpg', 'jpeg'],
                    disabledElements: [
                        'viewControlsButton',
                        'viewControlsOverlay'
                    ]
                },
                document.getElementById('webviewer'),
            ).then((instance) => {
                const { docViewer, Annotations } = instance;
                console.log("MY VIEWER:  " + viewer + "License Key updated: Feb 6,2025")
                const annotManager = docViewer.getAnnotationManager();
                annotManager.setCurrentUser(this.getCurrentUser());
                // if the document is a PDF, set filename to 'myfile.pdf'
                // if the document is an image, set filename to 'myfile.png'
                var filename = '';
                switch (fileType) {
                    case 'pdf':
                        filename = 'myfile.pdf';
                        break;
                    case 'png':
                        filename = 'myfile.png';
                        break;
                }
                instance.loadDocument(myBlob, { filename: filename });
                docViewer.on('documentLoaded', () => {
                    //load annotations last
                    annotManager.importAnnotations(xfdfString)
                });

                docViewer.setMargin(5);


                annotManager.on('annotationChanged', (annotations, action) => {
                    this.setState({ SaveChangesNotification: this.state.SaveChangesNotification + 1 })
                })

                instance.setHeaderItems(header => {
                    const exportButton = {
                        type: 'statefulButton',
                        initialState: 'default',
                        mount: update => {
                            const setExportState = state => {
                                return state;
                            };
                            instance.annotManager.on('annotationChanged', (annotations, action) => {
                                if (this.state.SaveChangesNotification > 1) {
                                    update(setExportState('highlight'));
                                }
                            })
                        },
                        states: {
                            default: {
                                img: '/assets/img/diskette.svg',
                                title: 'Save Changes',
                                onClick: async () => {
                                    xfdfString = await annotManager.exportAnnotations()
                                    await pushAnnotations(this.getCurrentId(), xfdfString)
                                    notification('Annotations Exported')
                                }
                            },
                            highlight: {
                                img: '/assets/img/diskette.svg',
                                title: 'Save Changes',
                                onClick: async (update) => {
                                    xfdfString = await annotManager.exportAnnotations()
                                    await pushAnnotations(this.getCurrentId(), xfdfString)
                                    notification('Annotations Saved')
                                    this.setState({ SaveChangesNotification: 1 })
                                    this.setAnnotationStatus(xfdfString)
                                    update();
                                },
                            }
                        },
                        dataElement: 'exportButton'
                    };

                    header.push(exportButton)


                    // import irrelevant, since it loads on start
                    // header.push({
                    //     type: 'actionButton',
                    //     img: '/assets/img/import.svg',
                    //     title: 'Import',
                    //     onClick: async () => {
                    //         await pullAnnotations(this.urlArgs[1]).then(res => xfdfString = res.data[0][34].value)
                    //         const annotations = annotManager.importAnnotations(xfdfString)
                    //         notification('Annotations Imported')
                    //     }
                    // });
                });

                // open annotations automatically
                instance.openElements(['notesPanel']);
                // sets annotate automatically
                instance.setToolbarGroup('toolbarGroup-Annotate');
                // hide elements
                instance.disableElements(['toolbarGroup-Shapes']);
                instance.disableElements(['toolbarGroup-Edit']);
                instance.disableElements(['toolbarGroup-Insert']);
                instance.disableElements(['toolbarGroup-View']);
                docViewer.setFitMode(docViewer.FitMode.FitWidth)

            });


        }

    }

    setAnnotationStatus(xfdfString) {
        var parser, xmlDoc
        parser = new DOMParser();
        xmlDoc = parser.parseFromString(xfdfString, "text/xml");

        const annots = xmlDoc.getElementsByTagName("annots")[0].childNodes
        let annotationsObj = {}
        // add entry to annotationsObj
        console.log(annots)
        annots.forEach((element) => {



            try {
                annotationsObj[element.getAttributeNode("name").value] = { children: [], status: "" }
            } catch (error) {
                console.log("Could not add to annotations obj:", element)
            }
        })

        // set children for annotations
        annots.forEach((element) => {
            try {


                const ds = element.getAttributeNode('date').value
                const fmtDs = `${ds.substr(2, 4)}-${ds.substr(6, 2)}-${ds.substr(8, 2)}T${ds.substr(10, 2)}:${ds.substr(12, 2)}:${ds.substr(14, 2)}`

                if (element.hasAttribute("inreplyto")) {
                    let state = ""
                    if (element.hasAttribute('state')) {
                        state = element.getAttributeNode('state').value
                    }
                    annotationsObj[element.getAttributeNode('name').value].topLevel = false
                    annotationsObj[element.getAttributeNode("inreplyto").value].status = state
                    annotationsObj[element.getAttributeNode("inreplyto").value].children.push(
                        {
                            name: element.getAttributeNode('name').value,
                            date: new Date(fmtDs),
                            status: state,
                        }
                    )
                }

            } catch (error) {
                console.log("Could not change reply things:", element)

            }
        })

        // check for any "incomplete" status
        let incomplete = []
        for (const [key, value] of Object.entries(annotationsObj)) {
            if (value.topLevel && value.status === "") {
                incomplete.push(key)
            }
        }

        if (incomplete.length > 0) {
            this.setState({ incompleteAnnotations: true })
        } else {
            this.setState({ incompleteAnnotations: false })
        }

    }


    render() {
        if (this.state.isLoaded && this.state.auth) {
            return (
                <div style={{ height: '100%' }}>
                    {
                        this.state.SaveChangesNotification > 1 &&
                        <p style={{ color: "red", position: "absolute", right: '0.5rem', top: '0.5rem' }}>Please save
                            changes
                            before closing page</p>
                    }
                    <NotificationContainer />
                    {/*<div class='pdf-button-container'>*/}
                    {/*    <button class='btn btn-primary'>Import</button>*/}
                    {/*    <button class='btn btn-success' onClick={this.exportAnnotations}>Export</button>*/}
                    {/*</div>*/}
                    <div id='webviewer' className="webviewer"></div>
                </div>);
        } else {
            return (<div className='text-danger fw-bolder p-5'>Unauthorized</div>)
        }
    }

}
